import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/Seo'
import Article from '../components/Article'
import Post from '../components/Course/Post'
import { ThemeContext } from '../layouts'

const CourseTemplate = props => {
  const {
    data: {
      course,
      authornote: { html: authorNote },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <Post post={course} authornote={authorNote} theme={theme} />
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo data={course} facebook={facebook} pathPrefix="/courses" />
    </React.Fragment>
  )
}

CourseTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CourseTemplate

//eslint-disable-next-line no-undef
export const courseQuery = graphql`
  query CourseBySlug($slug: String!) {
    course: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        prefix
      }
      frontmatter {
        title
        author
        category
        course
        description
        cover {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1200, maxHeight: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    authornote: markdownRemark(fileAbsolutePath: { regex: "/author/" }) {
      id
      html
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
